@tailwind base;
html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

:root {
  font-family: 'Noto Color Emoji', 'Open Sans', sans-serif;
  --black: #333;
  --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 50%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);

  --bg-gradient: linear-gradient(to bottom right, hsl(var(--hue), 95%, 99%) 100%, hsl(var(--hue), 95%, 84%));
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(45deg,var(--base) 25%,var(--complimentary2));
}

.gradient {
  background-image: linear-gradient(45deg,var(--complimentary2) 25%,var(--base));
}

.type-custom::after {
  content: '|';
  background-image: none;
  -webkit-text-fill-color: #333;
  -moz-text-fill-color: #333;
  animation: cursor 1.1s infinite step-start;
  position: relative;
  left: -0.5rem;
  font-weight: 300;
}

.color-hue {
  background-color: var(--complimentary2);
}

.color-hue-light {
  background-color: hsl(var(--hue-complimentary2), 95%, 75%);
}

.color-hue-dark {
  background-color: hsl(var(--hue-complimentary2), 95%, 25%);
}

.color-comp2 {
  background-color: hsl(var(--hue), 95%, 80%);
}

.color-comp {
  background-color: var(--complimentary1);
}

.color-comp3 {
  background-color: hsl(var(--hue), 90%, 50%);
}



.fill-hue {
  fill: var(--complimentary2);;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}